/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBKwcNUUHclSznd19HeQ6QtZtyAIVLAqHo",
  "appId": "1:886041332876:web:ad630138b7dd6168373fa9",
  "authDomain": "schooldog-i-white-ga.firebaseapp.com",
  "measurementId": "G-WKJ62VJS1B",
  "messagingSenderId": "886041332876",
  "project": "schooldog-i-white-ga",
  "projectId": "schooldog-i-white-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-white-ga.appspot.com"
}
